import React, { useState, useEffect } from "react";
import SubMenu from '../../components/submenu/events';
import Section_ComeBeaLion from '../../components/section-come-be-a-lion';
import Section_BecomePartner from '../../components/section-become-partner';
import HeaderLogo from "../../components/headerlogo";
import SEO from "../../components/seo";
import { getDescriptionPoint } from "../../redux/action/description/description";
import { getAlbum, getAlbumImages } from "../../redux/action/events/events";
import { useDispatch, useSelector } from "react-redux";
import { getBannerInfo, getLionBannerInfo } from "../../redux/action/banner/banner";
import Loader from "../Loader/Loader";
import { getTitleInfo } from "../../redux/action/others/other";

const PhotoGallery = () => {

    const dispatch = useDispatch()
    const [id, setId] = useState(1);
    const [section, setsection] = useState("events");
    const [subsection, setsubsection] = useState("photogallery");
    const { loading, album, Images } = useSelector((state) => state.event)
    const { loading: load, description } = useSelector((state) => state.description)
    const { loading: loadBanner, banner, lionbanner } = useSelector((state) => state.banner)
    const { loading: loadTitle, title } = useSelector((state) => state.others)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    useEffect(() => {
        dispatch(getAlbum());
        dispatch(getAlbumImages(id))
        dispatch(getLionBannerInfo())
        dispatch(getBannerInfo(section, subsection))
        dispatch(getDescriptionPoint(section, subsection))
        dispatch(getTitleInfo(section))
    }, [dispatch, id]);

    return (
        <>
            {loading || load || loadBanner ? (
                <Loader />
            ) : (

                <>
                    <SEO
                        title='Photo Gallery | Parsippany Lions Club'
                        description='The Parsippany Lions Club, Photo Gallery page features pictures from our events and initiatives, including vision screenings, eye exams, community clean-ups, food drives, fundraisers, and other events.'
                        keywords='Parsippany Lions Club, Volunteer, Photo Gallery, Photos, Events, Glimpse, Memories, Help, Humanity, Charity, Community service.'
                    />
                    <main>
                        <section class="about__banner gallery" style={{ backgroundImage: banner?.image ? `url(${process.env.REACT_APP_SERVER}/${banner.image})` : `url('../images/banner-events.jpg')` }}>
                            <div class="about__banner__bg">
                                <HeaderLogo />
                                <h1>{banner?.heading ? banner?.heading : `Events`}</h1>
                                <p>   Events /<span>  Photo Gallery</span></p>
                            </div>
                        </section>

                        <SubMenu activelink="photogallery" />

                        <section class="pt-5">
                            <div class="container px-5">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="photo-gallery-event-heading">
                                            {title?.title2 ? title.title2 : 'Photo Gallery'}
                                        </div>
                                        <div class="photo-gallery-event-by-line">

                                            <p>{description?.description1}</p>
                                            <p>{description?.description2}</p>
                                            <p>{description?.description3}</p>
                                            <p>{description?.description4}</p>
                                            <p>{description?.description5}</p>
                                        </div>
                                        <div class="row">
                                            {album && album.map((gallery, gindex) => (
                                                <div class="col-lg-6 mt-5">
                                                    <a type="button" class="equlee-tools" data-bs-toggle="modal" data-bs-target={"#modelAlbum_"+gallery.data.id}>
                                                        <div class="event-photo-gallery-box">
                                                            <div class="event-photo-gallery-image">
                                                                <img src={`${process.env.REACT_APP_SERVER}/${gallery.data.image}`} alt="" />
                                                            </div>
                                                            <div class="event-content-photo-gallery">
                                                                <div class="photo-gallery-event-text">
                                                                    {gallery.data.heading}
                                                                    <br /> <span>{gallery.data.date}</span>
                                                                </div>
                                                                <div class="photo-gallery-event-text-style">
                                                                    {/* <a href="" class="event-btn01">11 Photos</a> */}
                                                                    <a href="" class="event-btn01">{gallery.noOfPhotos} Photos</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <div class="modal fade" id={"modelAlbum_"+gallery.data.id} tabIndex={"-1"} aria-labelledby={"modelAlbum_"+gallery.data.id} aria-hidden="true" >
                                                        <div class="modal-dialog modal-xl">
                                                            <div class="modal-content">
                                                                <div class="modal-body">
                                                                    <div id={"carouselExample"+gindex} class="carousel slide" >
                                                                        <div class="carousel-inner">                                  
                                                                           
                                                                            {gallery.images && gallery.images.map((galleryImage, index) => (
                                                                                (index === 0 &&
                                                                                    <div key={gallery.data.id+"_"+galleryImage.id} class="carousel-item active">
                                                                                        <img src={`${process.env.REACT_APP_SERVER}/${galleryImage.image}`} class="d-block w-100" alt="" />
                                                                                        <p class="event-image-text">{galleryImage.heading}</p>
                                                                                    </div>
                                                                                )
                                                                            ))} 

                                                                            {gallery.images && gallery.images.map((galleryImage, index) => (
                                                                                (index > 0 &&
                                                                                    <div key={gallery.data.id+"_"+galleryImage.id} class="carousel-item ">
                                                                                        <img src={`${process.env.REACT_APP_SERVER}/${galleryImage.image}`} class="d-block w-100" alt="" />
                                                                                        <p class="event-image-text">{galleryImage.heading}</p>
                                                                                    </div>
                                                                                )
                                                                            ))}                                                                            
                                                                        </div>

                                                                    </div>
                                                                    <button class="carousel-control-prev" type="button" data-bs-target={"#carouselExample"+gindex} data-bs-slide="prev">
                                                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                        <span class="visually-hidden">Previous</span>
                                                                    </button>
                                                                    <button class="carousel-control-next" type="button" data-bs-target={"#carouselExample"+gindex} data-bs-slide="next">
                                                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                                        <span class="visually-hidden">Next</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Section_ComeBeaLion detail={lionbanner} />
                        <Section_BecomePartner />
                    </main>
                </>
            )}

        </>
    );
};

export default PhotoGallery;